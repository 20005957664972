<template>
    <sandbox-layout>
        <div class="p-8 space-y-16">
            <div>
                <h2 class="text-xl m-0 mb-4">
                    Example
                </h2>
                <div class="flex items-center space-x-8">
                    <router-link to="/sandbox/example-v1" class="w-36 py-2 px-4 text-center font-semibold link-grey bg-white rounded shadow hover:bg-gray-50">
                        Version 1
                    </router-link>
                </div>
            </div>
        </div>
    </sandbox-layout>
</template>

<script>
    import SandboxLayout from './SandboxLayout.vue';

    export default {
        components: {
            SandboxLayout,
        },
    };
</script>
