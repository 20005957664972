<template>
    <div>
        <button @click="opened = true">
            Open modal test
        </button>
        <activix-modal
            size="md"
            :opened="opened"
            @close="closeModal"
        >
            <template slot="header">
                <h4 class="modal-title">
                    Copier les modifications
                </h4>
            </template>

            <template slot="body">
                <div class="pb-4 text-center text-md text-gray-700">
                    Copier les modifications fait dans l'utilisateur parent dans des utilisateurs de compte enfants
                </div>
                <div class="relative">
                    <div class="flex justify-center max-h-screen-50 space-y-1 overflow-y-scroll pb-4">
                        <div class="absolute top-0 left-0 h-4 z-10 pointer-events-none | bg-gradient-to-b from-white" />
                        <div class="flex items-center flex-col w-full space-y-4">
                            <button class="link-blue" @click="selectAll">
                                {{ isAllSelected ? 'Unselect all' : 'Select all' }}
                            </button>
                            <button
                                class="flex items-center justify-center space-x-4 rounded py-2 px-3 | hover:shadow-sm hover:bg-gray-100"
                                :class="{'bg-gray-150': user.isChecked}"
                                :key="index"
                                @click="user.isChecked = !user.isChecked"
                                v-for="(user, index) in childUsers"
                            >
                                <activix-checkbox :value="user.isChecked" @click="user.isChecked = !user.isChecked" />
                                <div class="flex flex-col w-56 text-left truncate">
                                    <div class="text-lg" v-text="user.accountName" />
                                </div>
                            </button>
                        </div>
                        <div class="absolute bottom-0 left-0 w-full h-4 mb-12 z-10 pointer-events-none | bg-gradient-to-t from-white" />
                    </div>
                </div>
            </template>

            <template slot="footer">
                <div class="flex items-center justify-end">
                    <activix-button @click="closeModal">
                        Ignorer
                    </activix-button>
                    <activix-tooltip>
                        <span class="ml-2">
                            <activix-button type="primary" :disabled="selectedUsersLenght <= 0" @click="closeModal">
                                <span>Copier</span>
                                <span class="ml-2" v-text="selectedUsersLenght" v-if="selectedUsersLenght > 0" />
                            </activix-button>
                        </span>
                    </activix-tooltip>
                </div>
            </template>
        </activix-modal>
    </div>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                opened: false,
                childUsers: [
                    { accountName: 'Toyota Lachute', isChecked: false },
                    { accountName: 'Toyota Saint-Jérôme', isChecked: false },
                    { accountName: 'Toyota Sainte-Thérèse', isChecked: false },
                    { accountName: 'Toyota Boisbriand', isChecked: false },
                    { accountName: 'Toyota Blainville', isChecked: false },
                    { accountName: 'Toyota Sainte-Sophie', isChecked: false },
                    { accountName: 'Toyota Lachute', isChecked: false },
                ],
            };
        },

        computed: {
            isAllSelected() {
                return this.childUsers.filter((user) => user.isChecked === true).length === this.childUsers.length;
            },

            selectedUsersLenght() {
                return this.childUsers.filter((user) => user.isChecked === true).length;
            },
        },

        methods: {
            selectAll() {
                this.childUsers = this.childUsers.map((user) => {
                    return { ...user, isChecked: !this.isAllSelected };
                });
            },

            closeModal() {
                this.opened = false;
            },
        },

        mounted() {
            this.opened = true;
        },
    };
</script>
