<template>
    <div class="h-full min-h-screen flex flex-col">
        <div class="flex-1 relative flex">
            <main-nav-container>
                <sandbox-nav />
            </main-nav-container>

            <main
                class="flex-1 flex flex-col w-0 | transition-spacing duration-300"
                :class="{
                    'lg:ml-64': navOpened,
                    'max-h-screen': $route.meta.fixedContent,
                }"
            >
                <main-header>
                    <button class="btn-nav | md:mr-2" :class="{ 'px-3': mdLayout }">
                        <icon :class="mdLayout ? 'text-2xl' : 'text-lg'" name="regular/calendar" />
                    </button>
                    <button class="btn-nav | md:mr-2" :class="{ 'px-3': mdLayout }">
                        <icon :class="mdLayout ? 'text-2xl' : 'text-lg'" name="regular/alarm-bell" />
                    </button>

                    <div class="ml-2 flex-shrink">
                        <div class="text-grey-600 h-full truncate text-lg mr-4">
                            Dealer Name
                        </div>
                    </div>
                    <div class="relative flex items-center | md:ml-2">
                        <icon
                            class="absolute right-0 top-0 z-10 text-green-500"
                            :class="{ 'availability-status': !mdLayout }"
                            name="bold/sign-badge-circle"
                            scale="0.5"
                        />

                        <activix-tooltip placement="bottom" :content="userTooltip">
                            <div class="flex items-center group">
                                <activix-avatar
                                    class="rounded-full shadow-inner text-gray-650"
                                    :class="mdLayout ? 'text-xl' : 'text-2xl'"
                                    :size="mdLayout ? 28 : 35"
                                    username="John Doe"
                                />
                                <mq-layout mq="lg+">
                                    <icon class="text-grey-600 ml-3 text-xs | group-hover:text-grey-700" :name="$icons.expand" />
                                </mq-layout>
                            </div>
                        </activix-tooltip>
                    </div>
                </main-header>

                <div class="relative flex flex-col flex-shrink-0 flex-grow py-6 | lg:px-6 | transition-spacing duration-300">
                    <slot name="sidebar" />
                    <div class="relative flex-1">
                        <slot />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store.js';
    import MainNavContainer from '@/components/MainNavContainer.vue';
    import MainHeader from '@/components/MainHeader.vue';
    import SandboxNav from './components/SandboxNav.vue';

    export default {
        components: {
            MainNavContainer,
            MainHeader,
            SandboxNav,
        },

        computed: {
            ...mapState(useLayoutStore, ['navOpened']),

            userTooltip() {
                return `
                    <div class="font-semibold">John Doe</div>
                    Director
                `;
            },
        },
    };
</script>
