<template>
    <nav class="flex-grow flex-shrink-0 flex flex-col mt-8 mb-4">
        <!-- CLIENT -->
        <main-nav-header :content="$t('navs.sidebar.headers.client')" />

        <!--All Lead-->
        <main-nav-item
            icon="regular/house-chimney-2"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.allLead')"
        />

        <!-- LeadXpress -->
        <main-nav-item
            :icon="$icons.leadXpress"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.leadXpress')"
        />

        <!-- Walk-in -->
        <main-nav-item
            :icon="$icons.visit"
            :fill-icon="true"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.walkIn')"
        />

        <!-- Phone-up -->
        <main-nav-item
            icon="regular/phone"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.phoneUp')"
        />

        <!-- Renewal -->
        <main-nav-item
            :icon="$icons.renewal"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.renewal')"
        />

        <!-- Loyalty -->
        <main-nav-item
            :icon="$icons.loyalty"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.loyalty')"
        />

        <!-- Service -->
        <main-nav-item
            :icon="$icons.service"
            :flip-icon="true"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.service')"
        />

        <!-- TOOLS -->
        <main-nav-header class="mt-12" :content="$t('navs.sidebar.headers.tools')" />

        <!-- Sale Table -->
        <main-nav-item
            icon="regular/award-trophy-star-1"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.saleTable')"
        >
            <main-nav-sub-item
                icon="regular/target-center-1"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :active="$route.name == 'objectives.saleTable'"
                :title="$t('navs.sidebar.objectives')"
            />
        </main-nav-item>

        <!-- In Turn -->
        <main-nav-item
            :icon="$icons.inTurn"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.inTurn')"
        />

        <!-- WebBoost -->
        <main-nav-item
            :icon="$icons.webBoost"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.webBoost')"
        />

        <!-- Commercial -->
        <main-nav-item
            icon="regular/credit-card-1"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.commercial')"
        />

        <!-- Event -->
        <main-nav-item
            :icon="$icons.event"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.event')"
        />

        <!-- Activity Report -->
        <main-nav-item
            icon="regular/graph-stats"
            :to="{ name: 'sandbox' }"
            :show-active="false"
            :title="$t('navs.sidebar.activityReport')"
        >
            <main-nav-sub-item
                icon="regular/target-center-1"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :active="$route.name == 'objectives.activityReport'"
                :title="$t('navs.sidebar.objectives')"
            />
        </main-nav-item>

        <template v-if="!mdLayout">
            <!-- MANAGEMENT -->
            <main-nav-header class="mt-12" :content="$t('navs.sidebar.headers.management')" />

            <!-- Response template -->
            <main-nav-item
                icon="regular/content-pencil-write"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.templates')"
            />

            <!-- Automation -->
            <main-nav-item
                icon="regular/video-game-magic-wand"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.automations')"
            />

            <!-- Schedule -->
            <main-nav-item
                icon="regular/calendar-clock"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.schedule')"
            />

            <!-- Flow -->
            <main-nav-item
                icon="regular/filter-1"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.flow')"
            />

            <!-- Duplicates -->
            <main-nav-item
                :icon="$icons.duplicate"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.duplicates')"
            />

            <!-- Import -->
            <main-nav-item
                icon="regular/cloud-upload"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.import')"
            />

            <!-- Trashed lead -->
            <main-nav-item
                icon="regular/bin-paper"
                :to="{ name: 'sandbox' }"
                :show-active="false"
                :title="$t('navs.sidebar.trash')"
            />
        </template>

        <div class="flex-1 flex items-end justify-center mt-4">
            <icon class="text-3xl link-grey-light" name="regular/question-circle" @click="openFreshdesk" />
        </div>
    </nav>
</template>

<script>
    import MainNavHeader from '@/components/nav/MainNavHeader.vue';
    import MainNavItem from '@/components/nav/MainNavItem.vue';
    import MainNavSubItem from '@/components/nav/MainNavSubItem.vue';
    import FreshdeskUrl from '@/mixins/FreshdeskUrl.js';

    export default {
        components: {
            MainNavHeader,
            MainNavItem,
            MainNavSubItem,
        },

        mixins: [FreshdeskUrl],
    };
</script>
