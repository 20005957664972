<template>
    <component :is="componentVersion" />
</template>

<script>
    import { upperFirst, camelCase } from 'lodash-es';
    import SandboxLayout from './SandboxLayout.vue';

    // Import all components from versions folder
    const components = {};
    const requireComponent = require.context('./versions', false, /\.(js|vue)$/i);

    requireComponent.keys().forEach(fileName => {
        // Get component config
        const componentConfig = requireComponent(fileName);

        // Get PascalCase name of component
        const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));

        components[componentName] = componentConfig.default || componentConfig;
    });

    export default {
        components: {
            SandboxLayout,
            ...components,
        },

        computed: {
            componentVersion() {
                return this.$route.params.version;
            },
        },
    };
</script>
