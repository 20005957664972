<template>
    <sandbox-layout>
        <shrug class="flex h-full items-center justify-center text-3xl" />
    </sandbox-layout>
</template>

<script>
    import SandboxLayout from '../SandboxLayout.vue';
    import Shrug from './V1/Shrug.vue';

    export default {
        components: {
            SandboxLayout,
            Shrug,
        },
    };
</script>
